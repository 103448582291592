// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Form, Mutation} from '@supermove/components';
import {CommercialJobTimesheetForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';

// Components

const ConfirmCommercialJobTimesheetForm = ({job, onSuccess, onError, children}) => (
  <Form
    enableReinitialize
    initialValues={{
      commercialJobTimesheetForm: CommercialJobTimesheetForm.toForm(
        CommercialJobTimesheetForm.edit(job),
      ),
    }}
    onSubmit={(values) => {}}
  >
    {(form) => (
      <Mutation
        variables={{
          commercialJobTimesheetForm: CommercialJobTimesheetForm.toMutation(
            form.values.commercialJobTimesheetForm,
          ),
        }}
        mutation={ConfirmCommercialJobTimesheetForm.mutation}
        onCompleted={({confirmCommercialJobTimesheet: {job, errors}}) => {
          form.submitForm();
          form.setMutationErrors(errors);

          if (job) {
            onSuccess(job);
          } else {
            onError(errors);
          }
        }}
      >
        {(handleSubmit, {loading, error}) =>
          children({
            ...form,
            loading,
            handleSubmit,
          })
        }
      </Mutation>
    )}
  </Form>
);

// --------------------------------------------------
// Props
// --------------------------------------------------
ConfirmCommercialJobTimesheetForm.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

ConfirmCommercialJobTimesheetForm.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ConfirmCommercialJobTimesheetForm.fragment = gql`
  ${CommercialJobTimesheetForm.edit.fragment}

  fragment ConfirmCommercialJobTimesheetForm on Job {
    id
    ...CommercialJobTimesheetForm_edit
  }
`;

// Note(dan): isPostMoveFinished is used in the onSuccess handler from EditCommercialTimesheetCrewJobPage
ConfirmCommercialJobTimesheetForm.mutation = gql`
  mutation ConfirmCommercialJobTimesheetForm(
    $commercialJobTimesheetForm: CommercialJobTimesheetForm!,
  ) {
    confirmCommercialJobTimesheet(
      commercialJobTimesheetForm: $commercialJobTimesheetForm,
    ) {
      ${gql.errors}
      job {
        id
        isPostMoveFinished
      }
    }
  }
`;

export default ConfirmCommercialJobTimesheetForm;
