const _new = ({customerId, paymentMethodId}: any) => ({
  customerId,
  paymentMethodId,
});

const toForm = ({customerId, paymentMethodId}: any) => ({
  customerId,
  paymentMethodId,
});

const toMutation = ({customerId, paymentMethodId}: any) => ({
  customerId,
  paymentMethodId,
});

const CreateCustomerPaymentProfileForm = {
  new: _new,
  toForm,
  toMutation,
};

export default CreateCustomerPaymentProfileForm;
