const _new = ({stripeAccountId}: any) => ({
  stripeAccountId,
  number: '',
  expMonth: '',
  expYear: '',
  cvc: '',
  card: null, // Ref to the stripe-elements react component.
  error: null, // Error from the stripe api as the user types.

  // Private
  creditCardIsValid: false,
  loading: false,
});

const toForm = ({
  stripeAccountId,
  number,
  expMonth,
  expYear,
  cvc,
  card,
  error,
  creditCardIsValid,
  loading,
}: any) => {
  return {
    stripeAccountId,
    number,
    expMonth,
    expYear,
    cvc,
    card,
    error,

    // Private
    creditCardIsValid,
    loading,
  };
};

const StripePaymentForm = {
  new: _new,
  toForm,
};

export default StripePaymentForm;
