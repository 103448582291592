// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import CreateCustomerPaymentProfileForm from '@shared/modules/Payment/forms/CreateCustomerPaymentProfileForm';

const useCreateCustomerPaymentProfileMutation = ({
  createCustomerPaymentProfileForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      createCustomerPaymentProfileForm: CreateCustomerPaymentProfileForm.toForm(
        createCustomerPaymentProfileForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateCustomerPaymentProfileMutation.mutation,
    variables: {
      ...CreateCustomerPaymentProfileForm.toMutation(form.values.createCustomerPaymentProfileForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateCustomerPaymentProfileMutation.mutation = gql`
  mutation useCreateCustomerPaymentProfileMutation(
    $customerId: Int!,
    $paymentMethodId: String!,
  ) {
    response: createCustomerPaymentProfile(
      customerId: $customerId,
      paymentMethodId: $paymentMethodId,
    ) {
      ${gql.errors}
      error
      account {
        id
        stripePaymentMethodId
      }
    }
  }
`;

export default useCreateCustomerPaymentProfileMutation;
