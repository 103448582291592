// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useResponsive} from '@supermove/hooks';
import {PaymentMethod} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency, Percent} from '@supermove/utils';

// App
import Field from 'modules/App/components/Field';
import PageV2 from 'modules/App/components/PageV2';
import PaymentAmountModal from 'modules/Job/Customer/Project/Billing/Payment/components/PaymentAmountModal';
import PaymentSummaryTable from 'modules/Project/Billing/Payment/New/Tip/components/PaymentSummaryTable';
import PaymentAmountSection from 'modules/Project/Billing/Payment/components/PaymentAmountSection';

const Section = Styled.View`
  align-items: center;
`;

const TipOptionsContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TipOptionButton = Styled.Button`
  margin-horizontal: 10px;
  height: 100px;
  flex: 1;
`;

const TipOptionText = Styled.Text`
  ${Typography.Responsive.Heading2}
  color: ${colors.white};
`;

const TipTextInput = Styled.TextInput.H3`
`;

const TipInput = ({form, field}) => {
  return (
    <Field
      {...form}
      component={CurrencyInput}
      name={field}
      input={{
        returnKeyType: 'done',
        component: TipTextInput,
        placeholder: 'Enter a custom tip',
        setFieldValue: form.setFieldValue,
        setFieldTouched: form.setFieldTouched,
        style: {
          height: 60,
          width: 400,
        },
      }}
    />
  );
};

const getTipOptionText = ({tipSuggestion}) => {
  const percent = Percent.toForm(tipSuggestion.percent);
  const amount = Currency.toForm(tipSuggestion.amount);
  return `${percent} (${amount})`;
};

const handleUpdateWithTipOption = ({form, tipAmountField, tipAmount}) => {
  form.setFieldValue(tipAmountField, Currency.toForm(tipAmount));
};

const TipOptionsItem = ({text, handlePress}) => {
  const responsive = useResponsive();
  return (
    <TipOptionButton onPress={handlePress}>
      <TipOptionText responsive={responsive}>{text}</TipOptionText>
    </TipOptionButton>
  );
};

const TipOptions = ({project, handlePress}) => {
  const {tipSuggestions} = project.currentAggregateBill;

  return (
    <TipOptionsContainer>
      {tipSuggestions.map((tipSuggestion, index) => (
        <TipOptionsItem
          key={`${index}`}
          text={getTipOptionText({tipSuggestion})}
          handlePress={() => handlePress(tipSuggestion.amount)}
        />
      ))}
      {/* If there are tip options, we also add a custom/zero tip option at end */}
      {tipSuggestions.length > 0 && (
        <TipOptionsItem key={'CUSTOM'} text={'Cash / No Tip'} handlePress={() => handlePress(0)} />
      )}
    </TipOptionsContainer>
  );
};

const ProjectBillingTipAmountSection = ({
  project,
  form,
  paymentAmountField,
  paymentNameField,
  tipAmountField,
  paymentMethod,
}) => {
  const partialPaymentModal = useModal();

  const paymentAmount = _.get(form.values, paymentAmountField);
  const tipAmount = _.get(form.values, tipAmountField);
  const paymentAmountWithTip =
    Currency.convertToCents(paymentAmount) + Currency.convertToCents(tipAmount);
  // The payment fee is computed for display purposes only on the tip screen.
  const paymentFeeAmount = PaymentMethod.computePaymentFeeAmount(paymentMethod, {
    amount: paymentAmountWithTip,
  });

  return (
    <React.Fragment>
      <Section>
        <PageV2.Title>Add a tip?</PageV2.Title>
        <PageV2.Subtitle>
          100% of this tip goes to your movers. The amounts are based on the grand total of your
          entire move.
        </PageV2.Subtitle>
      </Section>
      <Space height={20} />
      <Section style={{flexDirection: 'row', justifyContent: 'center'}}>
        <PaymentAmountSection
          title={'Grand Total:'}
          amount={Currency.toForm(project.currentAggregateBill.totalWithoutTip)}
        />
      </Section>
      <Space height={16} />
      <Section>
        <TipInput form={form} field={tipAmountField} />
      </Section>
      <Space height={16} />
      <TipOptions
        project={project}
        paymentAmount={paymentAmount}
        handlePress={(tipAmount) => handleUpdateWithTipOption({form, tipAmountField, tipAmount})}
      />
      <Space height={40} />
      <Section>
        <PaymentSummaryTable
          rows={[
            {
              description: 'Payment Amount',
              amount: paymentAmount,
            },
            {
              description: 'Tip',
              amount: tipAmount === '' ? Currency.toForm(0) : tipAmount,
            },
            {
              description: paymentMethod?.displayName || 'Payment Fee',
              amount: Currency.display(paymentFeeAmount),
              isHidden: !paymentMethod?.feePercentage,
            },
            {
              description: 'Payment Total',
              amount: Currency.display(paymentAmountWithTip + paymentFeeAmount),
              style: {
                fontWeight: '700',
              },
            },
          ]}
        />
      </Section>
      <PaymentAmountModal
        form={form}
        paymentAmountField={paymentAmountField}
        paymentNameField={paymentNameField}
        key={partialPaymentModal.isOpen}
        isOpen={partialPaymentModal.isOpen}
        handleClose={partialPaymentModal.handleClose}
        project={project}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

ProjectBillingTipAmountSection.fragment = gql`
  ${PaymentAmountModal.fragment}
  ${PaymentMethod.computePaymentFeeAmount.fragment}
  fragment ProjectBillingTipAmountSection on Project {
    id
    currentAggregateBill {
      totalWithoutTip
      tipSuggestions {
        percent
        amount
      }
    }
    organization {
      id
      settings {
        id
        tipOptions
      }
    }
    ...PaymentAmountModal
  }
  fragment ProjectBillingTipAmountSection_PaymentMethod on PaymentMethod {
    id
    displayName
    feePercentage
    ...PaymentMethod_computePaymentFeeAmount
  }
`;

export default ProjectBillingTipAmountSection;
