// Libraries
import React from 'react';

// Supermove
import {Loading, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery} from '@supermove/hooks';
import {Job, Project} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// App
import PageFooter from 'modules/App/components/PageFooter';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ProjectBillingHeader from 'modules/Project/Billing/components/ProjectBillingHeader';
import ProjectBillsList from 'modules/Project/Billing/components/ProjectBillsList';

const Container = Styled.View`
  flex: 1;
`;

const Title = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Caption = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.gray.secondary};
  margin-top: 4px;
  margin-bottom: 20px;
`;

const Footer = () => {
  const {navigator, params} = useNavigation();
  const handleNavigateToPayment = () => {
    navigator.navigate('NewPaymentBillingProjectJobPage', {
      jobUuid: params.jobUuid,
      step: 'PROJECT_BILLING_ADDITIONAL_PAYMENT',
    });
  };
  return (
    <PageFooter>
      <PageFooter.Row style={{justifyContent: 'flex-end'}}>
        <PageFooter.Button color={colors.blue.interactive} onPress={handleNavigateToPayment}>
          Add Payments
        </PageFooter.Button>
      </PageFooter.Row>
    </PageFooter>
  );
};

const EditBillingProjectCrewJobContent = ({job, refetch}) => {
  return (
    <Container>
      <ProjectBillingHeader
        leftButtonType={'CLOSE'}
        title={Project.getName(job.project)}
        subtitle={Job.getFullName(job)}
      />
      <ScrollView style={{paddingHorizontal: 30, paddingVertical: 20}}>
        <ProjectBillsList
          project={job.project}
          title={<Title>Edit Bills</Title>}
          caption={
            <Caption>
              {`Here is the customer's bill. Please confirm the hours and fees are correct and add\n` +
                `additional items if needed.`}
            </Caption>
          }
          showGrandTotal
          showBalance
          isEditable={job.hasJobFeatureEditBillingAndPayments}
          isCollapsible
          showPrices
          showBillRules
          showOpenAllButton
          refetch={refetch}
        />
      </ScrollView>
      <Footer job={job} />
    </Container>
  );
};

const EditBillingProjectJobPage = () => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(EditBillingProjectJobPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      jobUuid: params.jobUuid,
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => (data ? <EditBillingProjectCrewJobContent job={data.job} refetch={refetch} /> : null)}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditBillingProjectJobPage.query = gql`
  ${Job.getFullName.fragment}
  ${Project.getName.fragment}
  ${ProjectBillsList.fragment}

  query EditBillingProjectJobPage($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      hasJobFeatureEditBillingAndPayments: hasJobFeature(kind: "CREW_EDIT_BILLING_AND_PAYMENTS")
      project {
        id
        ...Project_getName
        ...ProjectBillsList
      }
      ...Job_getFullName
    }
  }
`;

export default EditBillingProjectJobPage;
