// Libraries
import _ from 'lodash';
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import JobStepKind from '@shared/modules/Job/enums/JobStepKind';
import JobCurrentAction from 'modules/Job/Show/components/JobCurrentAction';

const getHasCurrentJob = ({viewer}) => {
  return !!_.get(viewer, 'currentJob.id');
};

const getIsCurrentJob = ({viewer, job}) => {
  return _.get(viewer, 'currentJob.id') === job.id;
};

// Job Event states
const isPrepareJobFinished = ({job}) => !!_.get(job, 'move.isPrepareJobFinished');
const hasCrewArrived = ({job}) => !!_.get(job, 'move.hasCrewArrived');
const isPreMoveTimesheetFinished = ({job}) => !!_.get(job, 'hasJobEventPreMoveTimesheetsCompleted');
const isPreMovePayrollTimesheetFinished = ({job}) =>
  !!_.get(job, 'hasJobEventPreMovePayrollTimesheetsCompleted');
const isPreMoveFinished = ({job}) => !!_.get(job, 'move.isPreMoveFinished');
const isCaptureInventoryFinished = ({job}) => !!_.get(job, 'hasJobEventCaptureInventoryFinalized');
const isDuringMoveTimesheetFinished = ({job}) =>
  !!_.get(job, 'hasJobEventDuringMoveJobTimesheetCompleted');
const isAfterMovePayrollTimesheetFinished = ({job}) =>
  !!_.get(job, 'hasJobEventAfterMovePayrollTimesheetsCompleted');
const isPostMovePrepared = ({job}) => !!_.get(job, 'move.isPostMovePrepared');
const isTimeTrackingFinalized = ({job}) => !!_.get(job, 'isTimeTrackingFinalized');
const isComplete = ({job}) => !!_.get(job, 'isComplete');
const isEnabledTimesheetsV2 = ({job}) => _.get(job, 'project.projectType.features.timesheetsV2');

const getCurrentEvent = ({job}) => {
  if (!isPrepareJobFinished({job})) {
    return 'prepare-job';
  } else if (!hasCrewArrived({job})) {
    return 'crew-arrive';
  } else if (job.hasJobStepCrewPreMoveBillableTimesheets && !isPreMoveTimesheetFinished({job})) {
    return JobStepKind.CREW_PRE_MOVE_BILLABLE_TIMESHEET;
  } else if (
    job.hasJobStepCrewPreMovePayrollTimesheets &&
    !isPreMovePayrollTimesheetFinished({job})
  ) {
    return JobStepKind.CREW_PRE_MOVE_PAYROLL_TIMESHEET;
  } else if (!isPreMoveFinished({job})) {
    return 'pre-move';
  } else if (job.hasJobStepCrewCaptureInventory && !isCaptureInventoryFinished({job})) {
    return 'capture-inventory';
  } else if (!isPostMovePrepared({job}) && !isEnabledTimesheetsV2({job})) {
    return 'prepare-post-move';
  } else if (
    isEnabledTimesheetsV2({job}) &&
    (!isDuringMoveTimesheetFinished({job}) || !job.isPostMoveFinished)
  ) {
    return JobStepKind.CREW_DURING_MOVE_BILLABLE_TIMESHEET;
  } else if (!isEnabledTimesheetsV2({job}) && !job.isPostMoveFinished) {
    return 'post-move';
  } else if (
    job.hasJobStepCrewAfterMovePayrollTimesheets &&
    !isAfterMovePayrollTimesheetFinished({job})
  ) {
    return JobStepKind.CREW_AFTER_MOVE_PAYROLL_TIMESHEET;
  } else if (!isEnabledTimesheetsV2({job}) && !isTimeTrackingFinalized({job})) {
    // DEPRECATED(cassie): Show finish move button for old flow only
    return 'finish-move';
  } else if (!isComplete({job})) {
    return 'complete-job';
  } else {
    return 'done';
  }
};

const getTimelineEvent = ({event}) => {
  switch (event) {
    case 'crew-arrive':
      return 'prepare-job';
    case 'prepare-post-move':
      return 'post-move';
    case 'complete-job':
      return 'done';
    default:
      return event;
  }
};

const getTitle = ({event, viewer = {}, job}) => {
  switch (event) {
    case 'prepare-job':
      return `Welcome ${viewer.firstName}, let's start the move.`;
    case 'crew-arrive':
      return `Hope you had a safe drive.`;
    case 'pre-move':
      return `Great, let's prepare documents for the customer to sign.`;
    case 'prepare-post-move':
      return 'As you complete the move, record the time you work.';
    case 'post-move':
      if (job.kind === 'COMMERCIAL') {
        return 'Next, have the customer sign the documents.';
      }
      return 'As you complete the move, record the time you work.';
    case 'finish-move':
      return `Fill out the remaining forms to finish the move.`;
    case 'complete-job':
      return 'Complete the job and submit to the back office.';
    default:
      return '';
  }
};

const getSubtitle = ({event, job}) => {
  switch (event) {
    case 'prepare-job':
      return 'Confirm the crew and navigate to the pick-up location.';
    case 'crew-arrive':
      return 'Record the time that you arrived.';
    case 'pre-move':
      return 'Fill out and confirm details to get the documents ready to sign.';
    case 'prepare-post-move':
      return 'Enter work, drive, and break times to automatically create the customer bill.';
    case 'post-move':
      if (job.kind === 'COMMERCIAL') {
        return 'Documents have been prepared and are ready.';
      }
      return 'Enter work, drive, and break times to automatically create the customer bill.';
    case 'finish-move':
      return `Enter the remaining details and submit the documents.`;
    case 'complete-job':
      return '';
    default:
      return '';
  }
};

const shouldDisableButton = ({hasCurrentJob, isCurrentJob, job}) => {
  if (job.isComplete) {
    // Completed jobs show the "View Summary" button.
    return false;
  } else if (hasCurrentJob && !isCurrentJob) {
    // You have a current job and this isn't your current job.
    return true;
  } else if (job.isPrepareStarted && !isCurrentJob) {
    // Someone else has started the job and it's not your current job.
    return true;
  }

  return false;
};

const JobCurrentEvent = ({viewer, job, navigator, refetch, children}) => {
  const hasCurrentJob = getHasCurrentJob({viewer});
  const isCurrentJob = getIsCurrentJob({viewer, job});
  const event = getCurrentEvent({job});
  const timelineEvent = getTimelineEvent({event});
  const title = getTitle({event, viewer, job});
  const subtitle = getSubtitle({event, job});
  const disabled = shouldDisableButton({hasCurrentJob, isCurrentJob, job});

  return (
    <JobCurrentAction event={event} job={job} navigator={navigator} refetch={refetch}>
      {({loading, text, color, handlePress}) =>
        children({
          loading,
          text,
          color,
          handlePress,

          disabled,
          hasCurrentJob,
          isCurrentJob,
          event,
          timelineEvent,
          title,
          subtitle,
        })
      }
    </JobCurrentAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobCurrentEvent.fragment = gql`
  ${JobCurrentAction.fragment}

  fragment JobCurrentEvent_Viewer on User {
    id
    firstName
    currentJob {
      id
      number
      kind
    }
  }

  fragment JobCurrentEvent_Job on Job {
    id
    uuid
    kind
    isPrepareStarted
    isTimeTrackingFinalized
    isPostMoveFinished
    isComplete
    hasJobStepCrewCaptureInventory: hasJobStep(kind: "CREW_CAPTURE_INVENTORY")
    hasJobStepCrewPreMoveBillableTimesheets: hasJobStep(kind: "CREW_PRE_MOVE_BILLABLE_TIMESHEET")
    hasJobStepCrewPreMovePayrollTimesheets: hasJobStep(kind: "CREW_PRE_MOVE_PAYROLL_TIMESHEET")
    hasJobStepCrewAfterMovePayrollTimesheets: hasJobStep(kind: "CREW_AFTER_MOVE_PAYROLL_TIMESHEET")
    hasJobEventCaptureInventoryFinalized: hasJobEvent(kind: "CAPTURE_INVENTORY_FINALIZED")
    hasJobEventPreMoveTimesheetsCompleted: hasJobEvent(kind: "PRE_MOVE_JOB_TIMESHEET_COMPLETED")
    hasJobEventPreMovePayrollTimesheetsCompleted: hasJobEvent(
      kind: "PRE_MOVE_PAYROLL_TIMESHEET_COMPLETED"
    )
    hasJobEventDuringMoveJobTimesheetCompleted: hasJobEvent(
      kind: "DURING_MOVE_JOB_TIMESHEET_COMPLETED"
    )
    hasJobEventAfterMovePayrollTimesheetsCompleted: hasJobEvent(
      kind: "AFTER_MOVE_PAYROLL_TIMESHEET_COMPLETED"
    )
    move {
      id
      hasCrewArrived
      isPrepareJobFinished
      isPreMoveFinished
      isPostMovePrepared
    }
    organization {
      id
      slug
    }
    project {
      id
      projectType {
        id
        features {
          timesheetsV2
        }
      }
    }
    ...JobCurrentAction
  }
`;

export default JobCurrentEvent;
