// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import BeginPaymentV3Form from '@shared/modules/Payment/forms/BeginPaymentV3Form';
import StripePaymentForm from '@shared/modules/Payment/forms/StripePaymentForm';

const useBeginPaymentV3MutationWithStripe = ({
  beginPaymentV3Form,
  stripePaymentForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      beginPaymentV3Form: BeginPaymentV3Form.toForm(beginPaymentV3Form),
      stripePaymentForm: StripePaymentForm.toForm(stripePaymentForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useBeginPaymentV3MutationWithStripe.mutation,
    variables: {
      beginPaymentV3Form: BeginPaymentV3Form.toMutation(form.values.beginPaymentV3Form),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useBeginPaymentV3MutationWithStripe.mutation = gql`
  mutation useBeginPaymentV3MutationWithStripe($beginPaymentV3Form: BeginPaymentV3Form!) {
    response: beginPaymentV3(beginPaymentV3Form: $beginPaymentV3Form) {
      ${gql.errors}
      payment {
        id
        stripePaymentClientSecret
      }
    }
  }
`;

export default useBeginPaymentV3MutationWithStripe;
