// Libraries
import React from 'react';

// App
import NewPaymentBillingProjectJobDefaultPage from 'modules/Job/Project/Billing/Payment/New/components/NewPaymentBillingProjectJobDefaultPage';

/**
 * DEPRECATED: Use DefaultPaymentPage instead.
 * TODO(jholston): Remove with isEnabledCustomPaymentMethods
 */
const VenmoMethodNewPaymentBillingProjectJobPage = () => {
  return (
    <NewPaymentBillingProjectJobDefaultPage
      paymentMethod={'VENMO'}
      title={'Please pay the amount in full.'}
      subtitle={'When you are ready, the crew will handle your payment.'}
      button={'Paid'}
    />
  );
};

export default VenmoMethodNewPaymentBillingProjectJobPage;
